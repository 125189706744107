import { Plugin } from "@nuxt/types";

import { hasNotPermissions, hasPermissions, validatePermissions } from "@/helpers/PermissionHelper";
import { IPagePermissionMeta, PermissionTinyDTO } from "@/services/DTOs/Permission";

export type IAuthority = (config: IPagePermissionMeta, argument?: "is" | "not") => boolean;

declare module "vue/types/vue" {
  interface Vue {
    $permission: IAuthority
  }
}

declare module "@nuxt/types" {
  interface NuxtAppOptions {
    $permission: IAuthority
  }

  interface Context {
    $permission: IAuthority
  }
}

declare module "vuex/types/index" {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface Store<S> {
    $permission: IAuthority
  }
}

const permission: Plugin = ({ store }, inject) => {
  inject("permission", (config: IPagePermissionMeta, argument?: "is" | "not"): boolean => {
    let validResult = true;

    if (config.permissions.length > 0) {
      const permissions = Array.from(new Set(config.permissions.map(p => p.category))).flatMap(p =>
        store.getters["OperatorPermission/getByCategory"](p) as PermissionTinyDTO[] ?? []
      );

      if (argument === "not") {
        validResult = validatePermissions(config, permissions, hasNotPermissions);
      } else {
        validResult = validatePermissions(config, permissions, hasPermissions);
      }
    }
    return validResult;
  });
};

export default permission;
