import { mapGetters } from "vuex";

import { IndexRouteManager } from "@/utils/page-routes";

const AddDataPointDialog = () =>
  import(
    /* webpackChunkName: "add-data-point-dialog" */
    "@/components/add-data-point-dialog/AddDataPointDialog"
  );

const SubscriptionButton = () =>
  import(
    /* webpackChunkName: "subscription-button" */
    "@/components/subscription-button/SubscriptionButton"
  );

const LogoutBtn = () =>
  import(
    /* webpackChunkName: "logout-btn" */
    "@/components/logout-btn/LogoutBtn"
  );

const Notification = () =>
  import(
    /* webpackChunkName: "notification" */
    "@/components/notification/Notification"
  );

const OperatorProfile = () =>
  import(
    /* webpackChunkName: "operator-profile" */
    "@/components/operator-profile/OperatorProfile"
  );

const OperatorNotification = () =>
  import(
    /* webpackChunkName: "operator-notification" */
    "@/components/operator-notification/OperatorNotification"
  );

const Snackbar = () =>
  import(
    /* webpackChunkName: "snackbar" */
    "@/components/snackbar/Snackbar"
  );

export default {
  name: "default",
  components: {
    AddDataPointDialog,
    LogoutBtn,
    Notification,
    OperatorNotification,
    OperatorProfile,
    SubscriptionButton,
    Snackbar
  },
  data() {
    return {
      drawer: false,
      sideBarRouteManager: IndexRouteManager.copy(),
      title: "Home",
      automatedTaskButtonIcon: "checkbox-multiple-marked"
    };
  },
  computed: {
    ...mapGetters(["isAuth"]),
    ...mapGetters("FeatureFlag", ["featureFlagOctopusSubscriptions"])
  }
};
