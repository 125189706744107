import { Plugin } from "@nuxt/types";
import Vue, { Directive, VNodeDirective } from "vue";

import { IAuthority } from "./Permission";
import { IPagePermissionMeta } from "@/services/DTOs/Permission";

export interface IOperatorsPermissionsDirectiveConfig {
  exactly: boolean;
  included: boolean;
  argument: "is" | "not";
}

const defaultDirectiveModifiers: IOperatorsPermissionsDirectiveConfig = {
  exactly: true,
  included: false,
  argument: "is"
};

const operatorPermissionDirectives: Plugin = ({ $permission }) => {
  const savedElem = new WeakMap();

  Vue.directive("permission", <Directive<HTMLElement, string>>{
    inserted: (el: HTMLElement, binding: VNodeDirective) => {
      if (!isAuthorityValid(binding, $permission)) {
        const replacer = document.createComment(" ");

        savedElem.set(el, { parentNode: el.parentNode, replacer });
        el.parentNode?.replaceChild(replacer, el);
      }
    },
    update: (el: HTMLElement, binding: VNodeDirective) => {
      if (!isAuthorityValid(binding, $permission)) {
        const replacer = document.createComment(" ");

        savedElem.set(el, { parentNode: el.parentNode, replacer });
        el.parentNode?.replaceChild(replacer, el);
      } else {
        const ctx = savedElem.get(el);

        if (ctx && ctx.replacer) {
          ctx.parentNode?.replaceChild(el, ctx.replacer);
        }
      }
    }
  });
};

const isAuthorityValid = (binding: VNodeDirective, $permission: IAuthority): boolean => {
  const directive = buildDirectiveConfig(binding);
  const values = binding.value as IPagePermissionMeta["permissions"] ?? [];

  return $permission(buildPermissionConfig(directive, values));
};

const buildDirectiveConfig = (binding: VNodeDirective): IOperatorsPermissionsDirectiveConfig => {
  const config = defaultDirectiveModifiers;

  if (binding.arg) {
    config.argument = binding?.arg as IOperatorsPermissionsDirectiveConfig["argument"] ?? defaultDirectiveModifiers.argument;
  }

  if (binding.modifiers) {
    if (binding.modifiers?.exact) {
      config.exactly = true;
      config.included = false;
    } else if (binding.modifiers?.include) {
      config.exactly = false;
      config.included = true;
    }
  }

  return config;
};

const buildPermissionConfig = (
  directive: IOperatorsPermissionsDirectiveConfig,
  values: IPagePermissionMeta["permissions"]
): IPagePermissionMeta => {
  return {
    directive: directive.included ? "inclusive" : "exact",
    permissions: values
  };
};

export default operatorPermissionDirectives;
