const Notification = () =>
  import(
    /* webpackChunkName: "notification" */
    "@/components/notification/Notification"
  );

export default {
  name: "empty",
  components: {
    Notification
  }
};
