import { Middleware } from "@nuxt/types";

const unsavedChangesHelper: Middleware = async({ store, redirect, from }) => {
  const isFormUnchanged = store.getters["FormHelper/isFormUnchanged"];

  if (!isFormUnchanged) {
    const leaveForm = await store.dispatch("FormHelper/confirmLeave") as boolean;

    if (leaveForm) {
      store.dispatch("FormHelper/cleanFormData");
    } else {
      redirect(from.fullPath);
    }
  }
};

export default unsavedChangesHelper;
