import { GaCookiesPlugin } from "@snowplow/browser-plugin-ga-cookies";
import { PerformanceTimingPlugin } from "@snowplow/browser-plugin-performance-timing";
import { newTracker } from "@snowplow/browser-tracker";

import { SnowplowService } from "@/services/SnowplowService";

newTracker("sp", process.env.snowPlowCollectorUrl!, {
  appId: process.env.snowPlowAppId,
  platform: "web",
  cookieDomain: undefined,
  discoverRootDomain: true,
  cookieName: "_sp_",
  cookieSameSite: "None",
  cookieSecure: true,
  encodeBase64: true,
  respectDoNotTrack: false,
  eventMethod: "post",
  resetActivityTrackingOnPageView: true,
  connectionTimeout: 10000,
  plugins: [GaCookiesPlugin(), PerformanceTimingPlugin()],
  contexts: {
    webPage: true
  }
});

(window as any).onNuxtReady(SnowplowService.initTracker);
