import Vue from "vue";

import { PagePermissionOverrideHelper } from "@/helpers/PagePermissionOverrideHelper";
import { IPagePermissionMeta } from "@/services/DTOs/Permission";

declare module "vue/types/vue" {
  interface VueConstructor {
    __pagePermissionMixin__?: boolean;
  }
}

declare module "vue/types/options" {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface ComponentOptions<V extends Vue> {
    pagePermissionMeta?: IPagePermissionMeta;
  }
}

const PermissionPageMixin = Vue.extend({
  beforeRouteEnter(to, _from, next) {
    next((vm) => {
      const options = vm.$options;
      const permissionConfig = options?.pagePermissionMeta as IPagePermissionMeta | undefined ?? undefined;
      const overriddenPage = PagePermissionOverrideHelper.decodePageAccessToken()?.page;

      if (!!overriddenPage && to.path.includes(overriddenPage)) {
        return next();
      } else if (permissionConfig) {
        if (!vm.$permission(permissionConfig)) {
          vm.$store.dispatch("setNotification", {
            type: "warning",
            message: "Hi there, Torrex. You do not have sufficient permission to access this page."
          });
          vm.$router.replace("/");
        }
      }
    });
  },
  beforeCreate() {
    const options = this.$options;
    const permissionConfig = options.pagePermissionMeta as IPagePermissionMeta | undefined;

    if (!options.computed) {
      options.computed = {};
    }

    if (permissionConfig) {
      options.computed.$isPageAccessAuthorized = function() {
        const vm = this as unknown as Vue;
        const config = options?.pagePermissionMeta as IPagePermissionMeta | undefined;

        return config
          ? vm.$permission(config)
          : false;
      };
    }
  },
  beforeDestroy() {
    const options = this.$options;

    if (options.computed) {
      delete options.computed.$isPageAccessAuthorized;
    }
  }
});

if (!Vue.__pagePermissionMixin__) {
  Vue.__pagePermissionMixin__ = true;
  Vue.mixin(PermissionPageMixin);
};
