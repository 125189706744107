import { Middleware } from "@nuxt/types";

const auth: Middleware = ({ store, route, redirect }) => {
  const allowedRoutes = ["/landing"];

  if (!store.getters.isAuth && !allowedRoutes.includes(route.path)) {
    store.dispatch("setNotification", {
      type: "warning",
      message: "Hi there, Torrex. Please login to continue."
    });
    redirect({ path: "/landing", query: { redirectTo: route.fullPath } });
  }
};

export default auth;
